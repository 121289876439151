#vh-fixer {
  height: 100vh;
  pointer-events: none;
  position: fixed;
  left: 0;
  top: 0;
}

body {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  touch-action: pan-y;
  overflow-x: hidden;

  background: #010101;
  color: white;
  font-family: "Bookmania", Georgia, "Times New Roman", Times, serif;
}

body * {
  /* cursor: url("./assets/scroll.png") 32 32, auto; */
  cursor: none;
}

/* Hide scrollbar for Chrome, Safari and Opera */
body::-webkit-scrollbar {
  display: none;
}
@keyframes rotation {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(-360deg);
  }
}

.loading-spin {
  animation: rotation 1s linear infinite;
}

@keyframes scroll {
  from {
    opacity: 0;
    transform: translateY(25px);
  }

  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.scroll-indicator {
  opacity: 0;
  animation: scroll 3s ease-out 8s infinite;
}

.nav-item,
.nav-button {
  /* cursor: url("./assets/click.png") 32 32, pointer; */
  cursor: none;
}

.nav-item::after {
  content: "";
  border-bottom: 0px solid #000;
  border-bottom: 0px solid var(--dash, #000);
  width: 0;
  transition: all 0.3s ease-in-out;
  position: absolute;
  left: 50%;
  bottom: 0;
}

.nav-item:hover::after {
  left: 0;
  width: 100%;
  border-bottom: 1px solid #000;
  border-bottom: 1px solid var(--dash, #000);
}

.animator-flower:hover,
.animator-flower:active {
  z-index: 1;
}

/* @keyframes scroll-bar {
  from {
    transform: none;
  }

  49% {
    transform: none;
  }

  50% {
    transform: translateX(calc(-100vw + 64px - 8px));
  }

  99% {
    transform: translateX(calc(-100vw + 64px - 8px));
  }

  to {
    transform: none;
  }
}

@keyframes scroll-bar-dot {
  from {
    background: white;
  }

  49% {
    background: white;
  }

  50% {
    background: transparent;
  }

  99% {
    background: transparent;
  }

  to {
    background: white;
  }
}

.scroll-bar {
  animation: scroll-bar 16s ease-in-out infinite;
}
.scroll-bar-a {
  animation: scroll-bar-dot 16s ease-in-out infinite;
}
.scroll-bar-b {
  animation: scroll-bar-dot 16s ease-in-out -8s infinite;
} */

.scroll-bar {
  transition: transform 0.3s ease-in-out;
}
.scroll-bar-a:active,
.scroll-bar-b:active {
  background-color: #f6f4ee;
}

.hoverable-button {
  background-color: rgba(241, 228, 186, 0.4);
}

.hoverable-button:active {
  background-color: rgba(78, 70, 47, 0.4);
}
